import { hot } from 'react-hot-loader/root'
import React, { useEffect, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import classNames from 'classnames/bind'

import 'react-circular-progressbar/dist/styles.css'
import axios from 'axios'
import styles from './asstes/style/app.less'
import CircleProgress from './components/CircleProgress'
import { useMutation, useQuery } from 'react-query'
import SendGreeting from './components/SendGreeting'
import FinishedView from './components/FInishedView'

const httpClient = axios.create({
  baseURL: process.env.API_DOMAIN
})

const words = [
  ['热血肺腾', '踏浪前行'],
  '传承肺癌',
  '进军血液',
  '备战肝癌',
  ['达伯舒', '塑造肺癌品牌形象'],
  ['达伯舒', '成功上市新适应症'],
  ['舒华合璧', '攻占血液'],
  ['准入为先', '专业为王'],
  ['健力宝', '四大重点运筹帷幄'],
  ['2021', '合力共赢']
]

const cx = classNames.bind(styles)

// const useWXBug = (callback) => {
//   useEffect(() => {
//     if (window.WeixinJSBridge) {
//       window.WeixinJSBridge.invoke('getNetworkType', {}, callback, false)
//     } else {
//       document.addEventListener(
//         'WeixinJSBridgeReady',
//         function () {
//           window.WeixinJSBridge.invoke('getNetworkType', {}, callback)
//         },
//         false
//       )
//     }
//   }, [])
// }

const App = () => {
  const audioRef = useRef()
  const [count, updateCount] = useState(0)
  const [messages, updateMessages] = useState([])

  const isShared = location.pathname.includes('shared')

  useEffect(() => {
    if (count === 0) {
      return
    }
    updateMessages(() => [{ id: Date.now(), content: words[count - 1] }])
  }, [count])

  // useWXBug(() => {
  //   audioRef.current?.play()
  // })

  const hasCheered = count > words.length || isShared

  const { mutate, data } = useMutation(async () => {
    const { data } = await httpClient.post('/addCheerTotal')
    return data.num
  })

  const { mutate: addGreeting } = useMutation(async (greeting) => {
    const { data } = await httpClient.post('/addCheerGreeting', { greeting })
    return data.success
  })

  const { data: total, isLoading, refetch } = useQuery(
    'total',
    async () => {
      const { data } = await httpClient.get('/getCheerTotal')
      return data.total
    },
    { enabled: hasCheered, refetchInterval: total >= 100 ? null : 3000 }
  )

  useEffect(() => {
    if (data) {
      refetch()
    }
  }, [data])

  const cheerFinished = total >= 100

  if (hasCheered && isLoading) {
    return <main></main>
  }

  if (cheerFinished) {
    return <FinishedView />
  }

  return (
    <main>
      {hasCheered ? (
        <motion.div className={cx('success-box')}>
          <div className={cx('welcome')}></div>
          {!isShared && (
            <div className={cx('your-rank')}>
              <span>您是第</span>
              <span className={cx('number', { small: data > 99 })}>
                {data || 0}
              </span>
              <span>位</span>
              <span className={cx('circle-text')}>“沸血”先锋</span>
            </div>
          )}
          <div
            className={cx('total-people', {
              shared: isShared
            })}
          >
            <p
              style={{
                display: 'flex',
                alignItems: 'baseline',
                padding: 0,
                margin: 0
              }}
            >
              已有
              <motion.span
                animate={{ scale: [1, 1.2, 1] }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  ease: 'linear',
                  repeatType: 'loop'
                }}
                className={cx('number')}
              >
                {total || 0}
              </motion.span>
              人助力“沸血”开启
            </p>
          </div>
          <div
            className={cx('progress-wrapper')}
            animate={{ scale: [0.8, 1, 0.8] }}
            transition={{
              duration: 1,
              repeat: Infinity,
              ease: 'linear',
              repeatType: 'loop'
            }}
          >
            <motion.div className="half-circle"></motion.div>
            <div className={cx('progress-circle')}>
              <CircleProgress cx={cx} current={total} />
            </div>
            {!isShared && (
              <SendGreeting onSubmit={(greeting) => addGreeting(greeting)} />
            )}
          </div>
        </motion.div>
      ) : (
        <>
          <motion.div
            className={cx('boom-btn')}
            transition={{
              easings: ['circIn', 'circOut']
            }}
            whileTap={{ scale: 1.2, transition: 0.2 }}
            onTap={async () => {
              if (count === words.length) {
                mutate()
              }
              updateCount((c) => c + 1)
            }}
          >
            <motion.div
              className={cx('circle', 'outside')}
              animate={{ rotate: [0, 360], opacity: [0.6, 1, 0.6] }}
              transition={{
                duration: 8,
                repeat: Infinity,
                ease: 'linear',
                repeatType: 'loop'
              }}
            ></motion.div>
            <motion.div
              className={cx('circle', 'inside')}
              animate={{ rotate: [0, -360], opacity: [1, 0.4, 1] }}
              transition={{
                duration: 10,
                repeat: Infinity,
                ease: 'linear',
                repeatType: 'loop'
              }}
            ></motion.div>
            <motion.div className={cx('circle', 'center')}></motion.div>
          </motion.div>
          <div className={cx('click-btn')}></div>

          <AnimatePresence>
            {messages.map(({ id, content }) => (
              <motion.div
                className={cx('greeting')}
                key={id}
                initial={{ opacity: 0 }}
                animate={{ opacity: [0, 1], translateY: [100, 0] }}
                transition={{ duration: 0.3, ease: ['easeIn'] }}
                exit={{ opacity: 0, translateY: [0, -100] }}
              >
                {Array.isArray(content) ? (
                  <div>
                    {content.map((c) => {
                      return <p key={c}>{c}</p>
                    })}
                  </div>
                ) : (
                  content
                )}
              </motion.div>
            ))}
          </AnimatePresence>
        </>
      )}
    </main>
  )
}

export default hot(App)
