import React from 'react'
import {
  buildStyles,
  CircularProgressbarWithChildren
} from 'react-circular-progressbar'

const CircleProgress = ({ cx, current }) => {
  const percentage = Math.min(Math.round(((current || 0) / 100) * 100), 100)
  return (
    <CircularProgressbarWithChildren
      value={percentage}
      strokeWidth={20}
      styles={buildStyles({
        rotation: 0.15,
        strokeLinecap: 'butt',
        textSize: '16px',
        pathTransitionDuration: 0.5,
        pathColor: '#fff',
        textColor: '#f88',
        trailColor: 'transparent',
        backgroundColor: 'transparent'
      })}
    >
      <p>
        <strong className={cx('percentage')}>{percentage}</strong>%
      </p>
    </CircularProgressbarWithChildren>
  )
}

export default CircleProgress
