import React, { useState } from 'react'
import styles from '../asstes/style/app.less'
import classNames from 'classnames/bind'
import toast, { Toaster } from 'react-hot-toast'
const cx = classNames.bind(styles)

const SendGreeting = ({ onSubmit }) => {
  const [greeting, updateGreeting] = useState('')
  const submit = async () => {
    if (onSubmit) {
      await onSubmit(greeting)
    }
    toast.success('提交成功', {
      style: {
        background: '#000',
        color: '#fff'
      }
    })
  }
  return (
    <div className={cx('sendGreeting')}>
      <input
        placeholder="写下你对2021的寄语"
        value={greeting}
        onChange={(e) => updateGreeting(e.target.value)}
      />
      <button onClick={() => submit()}>提交</button>
      <Toaster />
    </div>
  )
}

export default SendGreeting
