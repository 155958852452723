import React from 'react'
import styles from '../asstes/style/app.less'

import classnames from 'classnames/bind'
import { motion, useMotionValue, useTransform } from 'framer-motion'

const cx = classnames.bind(styles)

const FinishedView = () => {
  const val = useMotionValue(0)
  const sloganScale = useTransform(val, [0, 1], [0, 1])
  const flameMove = useTransform(val, [0, 1], [400, 0])
  return (
    <main>
      <motion.div className={cx('slogan2021')}></motion.div>
      <motion.div className={cx('flame')}></motion.div>
    </main>
  )
}

export default FinishedView
